import React, { useState, useEffect } from "react";

import MovieCard from "./MovieCard";
import SearchIcon from "./Search.svg";
import "./App.css";
import Footer from "./Footer";


const API_URL = "http://www.omdbapi.com?apikey=b6003d8a";
const prompp = alert('This is a movie search app made for anyone who is looking for a movie to watch... made by adeoluwa asojo but not entirely.... i got this i dea from an online project and decided to make it thank u...... happy use');
console.log(prompp);
const App = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [movies, setMovies] = useState([]);

  useEffect(() => {
    searchMovies("naruto");
  }, []);

  const searchMovies = async (title) => {
    const response = await fetch(`${API_URL}&s=${title}`);
    const data = await response.json();

    setMovies(data.Search);
    console.log(data.Search);
  };

  return (
    <>
      <div className="app">
        <h1>FilmFLIX....</h1>

        <div className="search">
          <input
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search for movies"
          />
          <img
            src={SearchIcon}
            alt="search"
            onClick={() => searchMovies(searchTerm)}
          />
        </div>

        {movies?.length > 0 ? (
          <div className="container">
            {movies.map((movie) => (
              <MovieCard movie={movie} />
            ))}
          </div>
        ) : (
          <div className="empty">
            <h2>No movies found</h2>
          </div>
        )}

      </div>
      <Footer />
    </>
  );
};

export default App;