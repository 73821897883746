import React from "react";

export const Footer = () => {
    return (

        <footer className="footer">
            <p>
                Copyright &copy; Xii programming
            </p>
            <p>
                This is a mastering react project Made by Adeoluwa Asojo.
            </p>
        </footer>
    );
};
export default Footer;